const initialState = {
  listUsaha: [],
};

const usahaReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case "SET_ALL_USAHA":
      return {
        ...state,
        listUsaha: data,
      };
    default:
      return state;
  }
};
export default usahaReducer;
