import axios from "axios";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { reduxSoldierMiddleware } from "redux-soldier";
//import axios from "axios";

// reducer
import authReducer from "./auth";
import dashboardReducer from "./dashboard";
import monitoringReducer from "./monitoring";
import userReducer from "./user";
import clientReducer from "./client";
import korwilReducer from "./korwil";
import areaManagerReducer from "./areaManager";
import marketingReducer from "./marketing";
import supervisorReducer from "./supervisor";
import cabangReducer from "./cabang";
import pengajuanReducer from "./Pengajuan";
import dashboardUtamaReducer from "./dashboardUtama";
import usahaReducer from "./usaha";

// function
export * from "./auth/function";
export * from "./monitoring/function";
export * from "./errorHandler";
export * from "./user/function";
export * from "./client/function";
export * from "./korwil/function";
export * from "./areaManager/function";
export * from "./marketing/function";
export * from "./supervisor/function";
export * from "./cabang/function";
export * from "./dashboardUtama/function";
export * from "./usaha/function";

// server api
// export const baseUrl = process.env.REACT_APP_URL
export const baseUrl = "https://newapikaltim.ptbap.net/";
// export const baseUrl = "https://newapibpd.waroengapp.my.id/";
// export const baseUrl = "https://t5w2szrw-9030.asse.devtunnels.ms/";
// export const baseUrl = "https://devapikaltim.ptbap.net/";
// export const baseUrl = "https://94gk6lfr-9030.asse.devtunnels.ms/";
// export const baseUrl = "http://192.168.110.66:9010/";
// export const baseUrl = "https://mmqst8hp-9050.asse.devtunnels.ms/";
// export const baseUrl = "http://localhost:9050/";

export const baseAxios = axios.create({
  baseURL: baseUrl,
  //timeout: 10000,
});

const rootReducer = combineReducers({
  userReducer,
  authReducer,
  clientReducer,
  dashboardReducer,
  monitoringReducer,
  korwilReducer,
  areaManagerReducer,
  marketingReducer,
  supervisorReducer,
  cabangReducer,
  pengajuanReducer,
  dashboardUtamaReducer,
  usahaReducer,
});

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(reduxSoldierMiddleware))
);
