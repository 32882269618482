import React from "react";
import DataTable from "react-data-table-component";
import moment from "moment";
import Select from "react-select";
import Swal from "sweetalert2";
import ReactDatetime from "react-datetime";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  TabContent,
  TabPane,
  Container,
  Collapse,
  Row,
  Col,
  Tab,
  OverlayTrigger,
  Tooltip,
  Modal,
  FormControl,
} from "react-bootstrap";
import { number } from "prop-types";
import DetailPengajuan from "./TabPengajuan/indexSteps";
import { useDispatch, useSelector } from "react-redux";
import { getAllPengajuan } from "stores/Pengajuan/function";
import { getAllStatusPengajuan } from "stores/Pengajuan/function";
import { Dna, InfinitySpin, LineWave, Oval } from "react-loader-spinner";
import { cekStatusPengajuan } from "stores/Pengajuan/function";
import ReactPaginate from "react-paginate";
import "./Pagination.css";
import { mirage, quantum } from "ldrs";
import { getAllJson } from "stores/Pengajuan/function";
import updateLogo from "../../../assets/img/changes.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { cekUpdateDataBpd } from "stores/Pengajuan/function";
import CryptoJS from "crypto-js";
import { getScoringByLoan } from "stores/Pengajuan/function";

function Pengajuan() {
  quantum.register();

  const itemsPerPage = 10;
  const modalDetailPengajuan = useSelector((state) => state.pengajuanReducer);
  const refreshPengajuan = useSelector(
    (state) => state.pengajuanReducer.modalDetailPengajuan
  );
  const auth = useSelector((state) => state.authReducer);
  const pengajuan = useSelector((state) => state.pengajuanReducer);
  const valDetail = pengajuan.detailPengajuan;
  const dispatch = useDispatch();
  const [listPengajuan, setListPengajuan] = React.useState([]);
  const [filterStatus, setFilterStatus] = React.useState(null);
  const [filterTujuanPenggunaan, setFilterTujuanPenggunaa] =
    React.useState(null);
  const [listStatus, setListStatus] = React.useState([]);
  const [listTujuanPenggunaan, setListTujuanPenggunaan] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [modalCekStatus, setModalCekStatus] = React.useState(false);
  const [modalCekUpdateData, setModalUpdateData] = React.useState(false);
  const [hasilCekStatus, setHasiCekStatus] = React.useState(null);
  const [detailPengajuan, setDetailPengajuan] = React.useState(null);
  const [pending, setPending] = React.useState(false);
  //Testing Pagination
  const [totalPerHalaman, setTotalPerHalaman] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [pilihOpsi, setPilihOpsi] = React.useState("");
  const [pilihTujuanPenggunaan, setPilihTujuanPenggunaan] = React.useState("");
  const [hours, setHours] = React.useState(0);

  const incrementHours = () => setHours((prev) => prev + 1);
  const decrementHours = () => setHours((prev) => (prev > 0 ? prev - 1 : 0));

  const handlePageSizeChange = (selectedOption) => {
    setTotalPerHalaman(selectedOption.value);
  };
  const [tglAwal, setTglAwal] = React.useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(
      0,
      0,
      0,
      0
    )
  );
  const [tglAkhir, setTglAkhir] = React.useState(new Date());

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleFilterChange();
    }
  };

  const handleFilterChange = () => {
    setPending(true);
    setPage(0);
    getAllPengajuan(dispatch, {
      status: pilihOpsi,
      page: 1,
      limit: totalPerHalaman,
      cari: searchText,
      tujuanPenggunaan: pilihTujuanPenggunaan,
    })
      .then((response) => {
        setPending(false);
      })
      .catch((error) => {
        setPending(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchText("");
  };

  const handleSubmit = () => {
    Swal.fire({
      title: "Konfirmasi Update",
      text: "Pastikan Jam yang anda kirim benar !!!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, Update",
      cancelButtonText: "Batal",
      allowOutsideClick: () => !Swal.isLoading(),
      showLoaderOnConfirm: true,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Loading...",
          text: "Mohon tunggu, sedang dicek.",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        cekUpdateDataBpd({
          jam: hours,
        }).then((response) => {
          Swal.close();
          if (response.data.status === 200) {
            Swal.fire({
              title: "Berhasil",
              text: response.data.message,
              icon: "success",
            });
          } else {
            Swal.fire({
              title: "Error",
              text: response.data.message,
              icon: "error",
            });
          }
        });
        handleCloseModalUpdatStatus();
        getAllPengajuan(dispatch, {
          status:
            filterStatus === null || filterStatus.value === ""
              ? ""
              : filterStatus.value,
          cari: searchText,
          page: page + 1,
          limit: totalPerHalaman,
          tujuanPenggunaan:
            filterTujuanPenggunaan === null ||
            filterTujuanPenggunaan.value === ""
              ? ""
              : filterTujuanPenggunaan.value,
        }).catch((err) => {
          Swal.close();
          console.log("ERRORRRRR", err);
          Swal.fire({
            title: "Error",
            text: "Terjadi kesalahan saat mengajukan pengajuan.",
            icon: "error",
          });
        });
      }
    });
  };

  const saveToLocalStorage = (data) => {
    // console.log(data, "JJJJ");
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      "your-secret-key"
    ).toString();
    localStorage.setItem("detailPengajuan", encryptedData);
  };

  const handleDetailsClick = (val) => {
    dispatch({ type: "SET_STEP_PENGAJUAN", data: false });
    dispatch({ type: "SET_DETAIL_PENGAJUAN", data: val });
    dispatch({ type: "HIDE_DOKUMEN" });
    localStorage.setItem("lastPage", "detail");
    saveToLocalStorage(val);
    getAllPengajuan(dispatch, {
      status: "",
      cari: val.id_pinjaman, // Query cari berdasarkan id_pinjaman
      page: 1,
      limit: 1,
      tujuanPenggunaan: val.stepEmpat.tujuanPenggunaan,
    });
  };

  React.useEffect(() => {
    const storedDetail = localStorage.getItem("detailPengajuan");

    if (storedDetail) {
      const bytes = CryptoJS.AES.decrypt(storedDetail, "your-secret-key");
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      // Mengupdate Redux state dengan data dari localStorage
      dispatch({ type: "SET_STEP_PENGAJUAN", data: false });
      dispatch({ type: "SET_DETAIL_PENGAJUAN", data: decryptedData });
    }
  }, [pengajuan.listPengajuan.data]);

  // const saveToLocalStorage = (data) => {
  //   localStorage.setItem("detailPengajuan", JSON.stringify(data));
  // };

  // // Fungsi ambil data dari localStorage
  // const getFromLocalStorage = () => {
  //   const storedData = localStorage.getItem("detailPengajuan");
  //   return storedData ? JSON.parse(storedData) : null;
  // };

  // const handleDetailsClick = (val) => {
  //   dispatch({ type: "SET_STEP_PENGAJUAN", data: false });
  //   dispatch({ type: "HIDE_DOKUMEN" });
  //   localStorage.setItem("lastPage", "detail");

  //   // Simpan detailPengajuan ke localStorage
  //   dispatch({ type: "SET_DETAIL_PENGAJUAN", data: val });
  //   saveToLocalStorage(val);
  // };

  // // Ambil data dari localStorage saat komponen dimuat
  // React.useEffect(() => {
  //   const storedDetailPengajuan = getFromLocalStorage();
  //   if (storedDetailPengajuan) {
  //     dispatch({ type: "SET_STEP_PENGAJUAN", data: false });
  //     dispatch({ type: "SET_DETAIL_PENGAJUAN", data: storedDetailPengajuan });
  //   }
  // }, [dispatch]);

  React.useEffect(() => {
    if (pengajuan.listPengajuan.data) {
      let tmp = pengajuan.listPengajuan.data.map((val) => {
        // console.log(val, "AAAAA");

        let tmpTanggalPengajuanBpd = "";
        let updateBy = "";
        for (var i = 0; i < val.history.length; i++) {
          if (val.history[i].status === "Diproses") {
            tmpTanggalPengajuanBpd = val.history[i].date;
            break;
          }
        }
        for (var i = 0; i < val.history.length; i++) {
          if (val.history[i].status === "Disetujui") {
            updateBy = val.history[i].keterangan;
            break;
          }
        }
        return {
          ...val,
          namaCabangPencairan: !val.lokasiPencairan
            ? "-"
            : val.lokasiPencairan.namaCabang,
          tanggalPengajuanBpd:
            tmpTanggalPengajuanBpd === ""
              ? "-"
              : moment(new Date(tmpTanggalPengajuanBpd)).format("DD MMMM YYYY"),
          tanggalPencairan:
            val.statusPengajuan === "Disetujui"
              ? moment(new Date(val.tglAkad)).format("DD MMMM YYYY")
              : "-",
          diupdateOleh: updateBy === "" ? "-" : updateBy,
          action: (
            <>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Details Pengajuan</Tooltip>}
              >
                <Button
                  className="mr-0"
                  // variant="info"
                  style={{
                    marginTop: 5,
                    backgroundColor: "#F75D59",
                    border: 0,
                  }}
                  onClick={() => {
                    // console.log(val, "KKKK");
                    handleDetailsClick(val);
                  }}
                >
                  <i class="fas fa-info"></i>
                </Button>
              </OverlayTrigger>{" "}
              {auth.role === "super admin" || auth.role === "admin" ? (
                <Button
                  className="ml-2"
                  disabled={val.statusPengajuan !== "Diproses"}
                  style={{
                    cursor:
                      val.statusPengajuan !== "Diproses"
                        ? "not-allowed"
                        : "pointer",
                    marginTop: 5,
                    backgroundColor:
                      val.statusPengajuan !== "Diproses" ? "grey" : "#DCE8F4",
                    border: 0,
                    fontWeight: "bold",
                    color:
                      val.statusPengajuan !== "Diproses" ? "white" : "#02478E",
                  }}
                  onClick={() => {
                    let siapCek = {
                      idPinjaman: val.id_pinjaman,
                      nik: val.nomorIdentitas,
                    };
                    Swal.fire({
                      title: "Loading ...",
                      allowOutsideClick: false,
                      showConfirmButton: false,
                      onBeforeOpen: () => {
                        Swal.showLoading();
                      },
                    });
                    cekStatusPengajuan(siapCek)
                      .then((response) => {
                        Swal.close();
                        if (response.status === 200) {
                          Swal.fire({
                            title: "Berhasil",
                            text: response.data.message,
                            icon: "success",
                          });
                          getAllPengajuan(dispatch, {
                            status:
                              filterStatus === null || filterStatus.value === ""
                                ? ""
                                : filterStatus.value,
                            cari: "",
                            page: page + 1,
                            limit: totalPerHalaman,
                          });
                          setModalCekStatus(!modalCekStatus);
                          setDetailPengajuan(val);
                          setHasiCekStatus(response.data);
                        } else {
                          Swal.fire({
                            title: "Gagal",
                            text: "Gagal melakukan cek status",
                            icon: "error",
                          });
                        }
                      })
                      .catch((error) => {
                        // Menutup loading Swal jika terjadi kesalahan
                        Swal.close();
                        console.error("Error:", error);
                      });
                    // setModalCekStatus(!modalCekStatus);
                    // setDetaiPengajuan(val);
                    // setHasiCekStatus(response.data);
                  }}
                >
                  Cek Status
                </Button>
              ) : null}
            </>
          ),
          action1: (
            <>
              {val.stepEmpat.tujuanPenggunaan === "2" ? (
                <>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Dokumen Serah Terima</Tooltip>}
                  >
                    <Button
                      className="mr-0"
                      // variant="info"
                      disabled={
                        val.stepEmpat.tujuanPenggunaan === "2" ||
                        val.urlChecklist === "-"
                      }
                      style={{
                        cursor:
                          val.stepEmpat.tujuanPenggunaan === "2" &&
                          val.urlChecklist === "-"
                            ? "not-allowed"
                            : "pointer",
                        marginTop: 5,
                        backgroundColor: "#C62D42",
                        border: 0,
                      }}
                      onClick={() => {
                        // handleDetailsClick(val);
                        window.open(val.urlChecklist, "_blank");
                      }}
                    >
                      <i class="fas fa-file-image"></i>
                    </Button>
                  </OverlayTrigger>
                </>
              ) : (
                "-"
              )}
            </>
          ),
        };
      });
      setListPengajuan(tmp);
    }
  }, [pengajuan.listPengajuan.data]);

  const handleCloseModalUpdatStatus = () => {
    setModalUpdateData(false);
    setHours(0);
  };

  const options = [
    { value: 10, label: "10" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];

  React.useEffect(() => {
    let tmp = [
      // {
      //   value: "",
      //   isDisabled: false,
      // },
      {
        value: "Menunggu Konfirmasi",
        label: "Menunggu Konfirmasi",
      },
      {
        value: "Diajukan",
        label: "Diajukan",
      },
      {
        value: "Diproses",
        label: "Diproses",
      },
      {
        value: "Disetujui",
        label: "Disetujui",
      },
      {
        value: "Revisi",
        label: "Revisi",
      },
      {
        value: "Ditolak",
        label: "Ditolak",
      },
    ];
    setListStatus(tmp);
  }, [pengajuan.listPengajuan.data, page, totalPerHalaman]);

  React.useEffect(() => {
    let tmp = [
      // {
      //   value: "",
      //   isDisabled: false,
      // },
      {
        value: "1",
        label: "Konsumtif",
      },
      {
        value: "2",
        label: "Produktif",
      },
    ];
    setListTujuanPenggunaan(tmp);
  }, [pengajuan.listPengajuan.data, page, totalPerHalaman]);

  const getStatusStyle = (status) => {
    switch (status) {
      case "Menunggu Konfirmasi":
        return {
          backgroundColor: "#FFF5CD",
          textColor: "#FFAF05",
          fontWeight: "bold",
        };
      case "Diajukan":
        return {
          backgroundColor: "#BAD9FD",
          textColor: "#1776F1",
          fontWeight: "bold",
        };
      case "Diproses":
        return {
          backgroundColor: "#fcceb3",
          textColor: "#fa5d02",
          fontWeight: "bold",
        };
      case "Disetujui":
        return {
          backgroundColor: "#E3FCDB",
          textColor: "#5AC967",
          fontWeight: "bold",
        };
      case "Revisi":
        return {
          backgroundColor: "#FFE2E6",
          textColor: "#FF2B4B",
          fontWeight: "bold",
        };
      case "Ditolak":
        return {
          backgroundColor: "#E10F00",
          textColor: "#ffffff",
          fontWeight: "bold",
        };
      default:
        return {};
    }
  };

  const getStatusConvertStyle = (status) => {
    switch (status) {
      case "":
        return {
          backgroundColor: "#FFEEE6",
          textColor: "#FF4F00",
          fontWeight: "bold",
        };
      case "gagal":
        return {
          backgroundColor: "#E10F00",
          textColor: "#ffffff",
          fontWeight: "bold",
        };
      case "sukses":
        return {
          backgroundColor: "#E3FCDB",
          textColor: "#5AC967",
          fontWeight: "bold",
        };
      default:
        return {};
    }
  };

  const customStyles = {
    rows: {
      style: {
        height: 50, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        // fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc",
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc",
        },
      },
    },
  };

  const colPengajuan = [
    {
      name: "No",
      width: "100px",
      center: true,
      selector: (row, index) => index + pengajuan.listPengajuan.pagingCounter,
      // (currentPage - 1) * totalPerHalaman +
      // pengajuan.listPengajuan.pagingCounter,
    },
    {
      name: "Referensi ID",
      minWidth: "350px",
      center: true,
      selector: (row) => row.id_pinjaman,
    },
    {
      name: "Calon Nasabah",
      minWidth: "350px",
      center: true,
      selector: (row) => row.namaLengkap,
    },
    {
      name: "Tujuan Penggunaan",
      minWidth: "250px",
      center: true,
      selector: (row) =>
        row.stepEmpat.tujuanPenggunaan === "1" ? "Konsumtif" : "Produktif",
    },
    {
      name: "Cabang Pencairan",
      minWidth: "300px",
      center: true,
      selector: (row) => row.namaCabangPencairan,
    },
    {
      name: "Sales",
      minWidth: "200px",
      center: true,
      selector: (row) => row.marketing.nama,
    },
    {
      name: "Tanggal Pengajuan Ke Supervisor",
      minWidth: "300px",
      center: true,
      selector: (row) => moment(new Date(row.createdAt)).format("DD MMMM YYYY"),
    },
    {
      name: "Tanggal Pengajuan Ke BPD",
      minWidth: "300px",
      center: true,
      selector: (row) => row.tanggalPengajuanBpd,
    },
    {
      name: "Tanggal Pencairan",
      minWidth: "300px",
      center: true,
      selector: (row) => row.tanggalPencairan,
    },
    {
      name: "Diupdate Oleh",
      minWidth: "300px",
      center: true,
      selector: (row) => row.diupdateOleh,
    },
    {
      name: "Status",
      minWidth: "200px",
      center: true,
      selector: (row) => row.statusPengajuan,
      cell: (row) => {
        const statusStyle = getStatusStyle(row.statusPengajuan);
        return (
          <div
            style={{
              fontWeight: statusStyle.fontWeight,
              backgroundColor: statusStyle.backgroundColor,
              color: statusStyle.textColor,
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {row.statusPengajuan}
          </div>
        );
      },
    },
    {
      name: "Dokumen Serah Terima",
      minWidth: "200px",
      center: true,
      selector: (row) => row.action1,
    },
    {
      name: "Status Upload SLIK",
      minWidth: "200px",
      center: true,
      selector: (row) => {
        if (!row.scoring) return "-";
        if (row.scoring.statusConvert === "") return "Diproses";
        if (row.scoring.statusConvert === "gagal") return "Gagal";
        if (row.scoring.statusConvert === "sukses") return "Sukses";
        return "-";
      },
      cell: (row) => {
        if (!row.scoring) {
          return "-";
        }

        let statusText = "-";
        switch (row.scoring.statusConvert) {
          case "":
            statusText = "Proses";
            break;
          case "gagal":
            statusText = "Gagal";
            break;
          case "sukses":
            statusText = "Sukses";
            break;
          default:
            statusText = "-";
        }

        const statusStyle = getStatusConvertStyle(row.scoring.statusConvert);

        return (
          <div
            style={{
              fontWeight: statusStyle.fontWeight,
              backgroundColor: statusStyle.backgroundColor,
              color: statusStyle.textColor,
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {statusText}
          </div>
        );
      },
    },
    {
      name: "Aksi",
      minWidth: "200px",
      center: true,
      selector: (row) => row.action,
    },
  ];

  React.useState(() => {
    getAllJson(dispatch);
  }, []);

  React.useEffect(() => {
    setPending(true);
    getAllPengajuan(dispatch, {
      status:
        filterStatus === null || filterStatus.value === ""
          ? ""
          : filterStatus.value,
      cari: searchText,
      page: page + 1,
      limit: totalPerHalaman,
      tujuanPenggunaan:
        filterTujuanPenggunaan === null || filterTujuanPenggunaan.value === ""
          ? ""
          : filterTujuanPenggunaan.value,
    })
      .then((response) => {
        // setLoading(false);
        setPending(false);
      })
      .catch((error) => {
        // setLoading(false);
        setPending(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  }, [modalDetailPengajuan.modalDetailPengajuan, page, totalPerHalaman]);

  // React.useEffect(() => {
  //   getAllPengajuan(dispatch, { status: "" });
  // }, [modalDetailPengajuan.modalDetailPengajuan]);

  return (
    <>
      <Modal
        size="lg"
        show={modalCekUpdateData}
        onHide={() => {
          setModalUpdateData(false);
          handleCloseModalUpdatStatus();
        }}
        aria-labelledby="example-modal-sizes-title-lg"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Col md="12">
            <Card.Title
              as="h4"
              style={{
                fontWeight: "bold",
                textTransform: "uppercase",
                fontFamily: "sans-serif",
                textAlign: "center",
              }}
            >
              Cek Update Data Pengajuan
              <hr />
            </Card.Title>
            <Card className="stacked-form" style={{ border: 0 }}>
              <Card.Body>
                <Row className="justify-content-center mb-3">
                  <Col xs="auto" className="text-center">
                    <p style={{ fontSize: "1.1rem", fontWeight: "bold" }}>
                      Jam ke belakang :
                    </p>
                  </Col>
                </Row>
                <Row className="justify-content-center mb-3">
                  <Col xs="auto" className="text-center">
                    <Button
                      variant="secondary"
                      onClick={decrementHours}
                      className="mx-2"
                      style={{ minWidth: "40px" }}
                    >
                      -
                    </Button>
                    <FormControl
                      type="number"
                      value={hours}
                      readOnly
                      className="text-center mx-2"
                      style={{ maxWidth: "80px", display: "inline-block" }}
                    />
                    <Button
                      variant="secondary"
                      onClick={incrementHours}
                      className="mx-2"
                      style={{ minWidth: "40px" }}
                    >
                      +
                    </Button>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col xs="auto" className="text-center">
                    <Button
                      className="mt-3"
                      style={{
                        backgroundColor: "#53ABA0",
                        border: 0,
                        width: "100px",
                      }}
                      onClick={handleSubmit}
                    >
                      OK
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={modalCekStatus}
        onHide={() => setModalCekStatus(!modalCekStatus)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        {/* <Modal.Header closeButton></Modal.Header> */}
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Cek Status Pengajuan
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              {hasilCekStatus &&
              (hasilCekStatus.status === "101" ||
                hasilCekStatus.status === "200") ? (
                <>
                  <Row>
                    <Col sm="3">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        Refference ID
                      </label>
                    </Col>
                    <Col sm="o">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        :
                      </label>
                    </Col>
                    <Col sm="6">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        {detailPengajuan && detailPengajuan.id_pinjaman}
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="3">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        Nama Nasabah
                      </label>
                    </Col>
                    <Col sm="o">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        :
                      </label>
                    </Col>
                    <Col sm="6">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        {detailPengajuan && detailPengajuan.namaLengkap}
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="3">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        Status Pengajuan
                      </label>
                    </Col>
                    <Col sm="o">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        :
                      </label>
                    </Col>
                    <Col sm="6">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        {hasilCekStatus && hasilCekStatus.keterangan}
                      </label>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Row>
                    <Col sm="3">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        Refference ID
                      </label>
                    </Col>
                    <Col sm="o">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        :
                      </label>
                    </Col>
                    <Col sm="6">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        {detailPengajuan && detailPengajuan.id_pinjaman}
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="3">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        Nama Nasabah
                      </label>
                    </Col>
                    <Col sm="o">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        :
                      </label>
                    </Col>
                    <Col sm="6">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        {detailPengajuan && detailPengajuan.namaLengkap}
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="3">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        Pesan
                      </label>
                    </Col>
                    <Col sm="o">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        :
                      </label>
                    </Col>
                    <Col sm="6">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        {hasilCekStatus && hasilCekStatus.message}
                      </label>
                    </Col>
                  </Row>
                </>
              )}
            </Card.Body>
          </Card>
          <Button
            className="mr-0"
            // disabled={val.statusPengajuan !== "Diproses"}
            style={{
              marginBottom: 20,
              backgroundColor: "#53ABA0",
              border: 0,
            }}
            onClick={() => {
              // console.log("sadsadasdas");
              setModalCekStatus(!modalCekStatus);
              getAllPengajuan(dispatch, {
                status:
                  filterStatus === null || filterStatus.value === ""
                    ? ""
                    : filterStatus.value,
                cari: "",
                page: page + 1,
                limit: totalPerHalaman,
              });
              window.location.reload();
            }}
            // onClick={() => {
            //   let siapCek = {
            //     idPinjaman: val.id_pinjaman,
            //     nik: val.nomorIdentitas,
            //   };
            //   cekStatusPengajuan(siapCek).then((response) => {
            //     // console.log(response, "KKK");
            //     setModalCekStatus(!modalCekStatus);
            //     setDetaiPengajuan(val);
            //     setHasiCekStatus(response.data);
            //   });
            // }}
          >
            OK
          </Button>
        </Col>
      </Modal>

      {modalDetailPengajuan.modalDetailPengajuan ? (
        <>
          <Container fluid>
            <Row>
              <Col md="12">
                <Card>
                  <Card.Header>
                    <Card.Title
                      as="h3"
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        textTransform: "uppercase",
                      }}
                    >
                      List Pengajuan
                      <hr></hr>
                    </Card.Title>
                    <Tab.Content>
                      <Card style={{ border: 0, marginTop: 20 }}>
                        <Row
                          style={{
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          {/* <Col md={2}>
                            <h5
                              className="title mt-2"
                              style={{ fontWeight: "bold" }}
                            >
                              Tanggal Awal
                            </h5>
                            <div>
                              <ReactDatetime
                                inputProps={{
                                  className: "form-control",
                                  placeholder: "Date Picker Here",
                                  readOnly: true,
                                  style: {
                                    cursor: "default",
                                    color: "black",
                                    backgroundColor: "white",
                                  },
                                }}
                                onChange={(e) => {
                                  e.toDate().setHours(0, 0, 0, 0);
                                  setTglAwal(e.toDate().setHours(0, 0, 0, 0));
                                }}
                                value={tglAwal}
                                initialValue={tglAwal}
                                timeFormat={false}
                              />
                            </div>
                          </Col>
                          <Col md={2}>
                            <h5
                              className="title mt-2"
                              style={{ fontWeight: "bold" }}
                            >
                              Tanggal Akhir
                            </h5>
                            <div>
                              <ReactDatetime
                                inputProps={{
                                  className: "form-control",
                                  placeholder: "Date Picker Here",
                                  readOnly: true,
                                  style: {
                                    cursor: "default",
                                    color: "black",
                                    backgroundColor: "white",
                                  },
                                }}
                                onChange={(e) => {
                                  e.toDate().setHours(0, 0, 0, 0);
                                  setTglAkhir(e.toDate().setHours(0, 0, 0, 0));
                                }}
                                value={tglAkhir}
                                initialValue={tglAkhir}
                                timeFormat={false}
                              />
                            </div>
                          </Col> */}
                          <Col md={4} style={{ marginTop: 45 }}>
                            <div>
                              <InputGroup>
                                <Form.Control
                                  style={{
                                    paddingLeft: "10px",
                                  }}
                                  type="text"
                                  value={searchText}
                                  onChange={handleSearch}
                                  onKeyDown={handleKeyDown}
                                  placeholder="Reffrence ID/Nasabah"
                                />
                                <InputGroup.Append>
                                  <InputGroup.Text
                                    style={{ cursor: "pointer" }}
                                    onClick={handleClearSearch}
                                  >
                                    <i class="fas fa-times"></i>
                                  </InputGroup.Text>
                                </InputGroup.Append>
                              </InputGroup>
                            </div>
                          </Col>
                          <Col md={3} style={{ marginTop: 45 }}>
                            <div>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                name="Pilih Status"
                                value={filterStatus}
                                onChange={(selectedOption) => {
                                  setFilterStatus(selectedOption);
                                  setPilihOpsi(selectedOption.value);
                                }}
                                options={[
                                  { value: "", label: "All" },
                                  ...listStatus,
                                ]}
                                onKeyDown={handleKeyDown}
                                placeholder="Pilih Status"
                              />
                            </div>
                          </Col>
                          <Col md={3} style={{ marginTop: 45 }}>
                            <div>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                name="Pilih Status"
                                value={filterTujuanPenggunaan}
                                onChange={(selectedOption) => {
                                  setFilterTujuanPenggunaa(selectedOption);
                                  setPilihTujuanPenggunaan(
                                    selectedOption.value
                                  );
                                }}
                                options={[
                                  { value: "", label: "All" },
                                  ...listTujuanPenggunaan,
                                ]}
                                onKeyDown={handleKeyDown}
                                placeholder="Pilih Tujuan Penggunaan"
                              />
                            </div>
                          </Col>
                          <Col style={{ marginTop: 45 }}>
                            <Button
                              // variant="info"
                              style={{
                                fontSize: 16,
                                backgroundColor: "#00BFFF",
                                border: 0,
                                paddingTop: 10,
                                paddingBottom: 7,
                              }}
                              onClick={handleFilterChange}
                            >
                              <i class="fas fa-search"></i>
                            </Button>
                          </Col>
                        </Row>
                      </Card>
                      <div style={{ textAlign: "end" }}>
                        {auth.role === "super admin" ||
                        auth.nopeg === "05230394" ? (
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Cek Update Data</Tooltip>}
                          >
                            <Button
                              className="ml-1"
                              // variant="info"
                              style={{
                                marginTop: 5,
                                backgroundColor: "#A2CFFE",
                                border: 0,
                                fontWeight: "bold",
                                color: "black",
                              }}
                              onClick={() => {
                                // console.log(val, "KKKK");
                                setModalUpdateData(!modalCekUpdateData);
                              }}
                            >
                              Update All Data
                            </Button>
                          </OverlayTrigger>
                        ) : null}
                      </div>
                    </Tab.Content>
                  </Card.Header>
                  <Card.Body>
                    {pending ? (
                      <div
                        className="loader-container"
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        {/* <InfinitySpin width="200" color="#413839" /> */}
                        <l-quantum
                          size="50"
                          speed="2.75"
                          color="#577d86"
                        ></l-quantum>
                        <p
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: 19,
                          }}
                        >
                          Loading ....
                        </p>
                      </div>
                    ) : (
                      <div>
                        <DataTable
                          columns={colPengajuan}
                          data={listPengajuan}
                          // data={listPengajuan.filter(
                          //   (row) =>
                          //     row.id_pinjaman
                          //       .toLowerCase()
                          //       .includes(searchText.toLowerCase()) ||
                          //     row.namaLengkap
                          //       .toLowerCase()
                          //       .includes(searchText.toLowerCase())
                          // )}
                          customStyles={customStyles}
                          paginationPerPage={totalPerHalaman}
                          progressPending={pending}
                        />
                        <div style={{ marginTop: 35 }}>
                          <Row
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Col>
                              <Row style={{ alignItems: "center" }}>
                                <div
                                  style={{
                                    paddingRight: 0,
                                    marginLeft: 20,
                                    marginRight: 10,
                                  }}
                                >
                                  <label style={{ fontWeight: "bold" }}>
                                    Rows per page
                                  </label>
                                </div>
                                <Col sm="3" style={{ paddingLeft: 0 }}>
                                  <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    name="Pilih Client"
                                    value={options.find(
                                      (option) =>
                                        option.value === totalPerHalaman
                                    )}
                                    onChange={handlePageSizeChange}
                                    options={options}
                                    placeholder="Rows per page"
                                  />
                                </Col>
                              </Row>
                            </Col>

                            <Col>
                              <ReactPaginate
                                forcePage={page}
                                containerClassName="paginations justify-content-end"
                                previousClassName="pages-items-containerrs"
                                previousLinkClassName="pages-links-labells"
                                nextClassName="pages-items-containerrs"
                                nextLinkClassName="pages-links-labells"
                                breakClassName="pages-items-containerrs"
                                pageCount={pengajuan.listPengajuan.totalPages}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={1}
                                pageLinkClassName="pages-links-labells"
                                pageClassName="pages-items-containerrs"
                                activeClassName="activees"
                                activeLinkClassName="activess"
                                nextLabel=" > "
                                previousLabel=" < "
                                initialPage={page}
                                disableInitialCallback={true}
                                onPageChange={(val) => {
                                  setPage(val.selected);
                                }}
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      ) : (
        <>
          <DetailPengajuan />
        </>
      )}
    </>
  );
}

export default Pengajuan;
